import {trans} from 'laravel-vue-i18n'
/**
 * @typedef {0,1,2,3} AccessLevel
 * 0=none, 1=r, 2=rw, 3=admin
 */

/**
 * @type {{[AccessLevel]: string}}
 */
const accessLevels = {
  0: 'have_no_access',
  1: 'can_read',
  2: 'can_read_write',
  3: 'have_full_access'
}

/**
 * @param {AccessLevel} min
 * @param {AccessLevel} max
 */
export function getAccessLevelOptions(min, max) {
  const result = []
  for (let i = min; i <= max; i++) {
    result.push({label: accessLevels[i], value: i})
  }
  return result
}

/**
 * @param {AccessLevel} accessLevel
 */
export function accessFormat(accessLevel) {
  return trans(accessLevels[accessLevel])
}

export function datetimeFormat(str) {
  const locale = document.querySelector('html').getAttribute('lang')
  const date = new Date(str)
  const currentYear = (new Date()).getFullYear()
  const options = {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false}
  if (date.getFullYear() !== currentYear) {
    options.year = 'numeric'
  }

  // in some instances Date object's localization to kk don't work
  if (locale === "kk") {
    return formatDateInKazakh(date);
  }

  return date.toLocaleString(locale, options)
}

function formatDateInKazakh(date) {
  // Months in Kazakh
  const monthsKazakh = [
      "Қаң.", "Ақп.", "Нау.", "Сәу.", "Мам.", "Мау.", 
      "Шіл.", "Там.", "Қыр.", "Қаз.", "Қар.", "Жел."
  ];

  const day = date.getDate().toString().padStart(2, '0');
  const month = monthsKazakh[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

export function booleanFormat(val) {
  return trans(val ? 'yes' : 'no')
}

export function editableFormat(val) {
  return trans(val ? 'ready' : 'preparing')
}

/**
 * @param {{ru: string}} val
 * @returns {*}
 */
export function roqedTranslatableFormat(val) {
  const locale = 'ru'
  const maxLen = 64
  const toReturn = val?.[locale] ?? ''
  return toReturn.length > maxLen
    ? `${toReturn.substring(0, maxLen-1)}…`
    : toReturn
}

export function replaceEmptyStringWithNull(val) {
  return val === '' ? null : val
}

export function inArrayFormat(needles, haystack) {
  if (!Array.isArray(needles))
    needles = [needles]

  return needles.map(n => haystack[`${n}`]).join(', ')
}

export function getQueryParamsObject() {
  const currentUrl = window.location.search
  const urlParams = new URLSearchParams(currentUrl)
  const result = {}
  urlParams.forEach((value, key) => {
    let isArray = false
    while (key.endsWith('[]')) {
      key = key.slice(0, -2)
      if (!result[key]) {
        result[key] = []
      }
      isArray = true
    }

    if (!isArray) {
      result[key] = value
    } else {
      result[key].push(value)
    }
  })
  return result
}

export function unwrapErrorBag(errorBag) {
  const result = {}
  Object.entries(errorBag).forEach(([key, value]) => {
    const keyParts = key.split('.')
    let obj = result
    for (let i = 0; i < keyParts.length; i++) {
      const keyPart = keyParts[i]
      if (i < keyParts.length-1) {
        if (!obj[keyPart]) obj[keyPart] = {}
        obj = obj[keyPart]
      } else {
        obj[keyPart] = value
      }
    }
  })
  return result
}

export function mapIdMapToSelectOptions(map) {
  return Object.entries(map).map(([value, label]) => ({ label, value }))
}

export function mapComplexIdMapToSelectOptions(map, labelKey) {
  return Object.entries(map).map(([value, v]) => ({ label: v[labelKey], value }))
}

export function getHomeworkStatus(homework) {
  if (homework.assigned_at) {
    return 'assigned';
  }

  if (!homework.editable) {
    return 'generating';
  }

  return 'draft';
}

export function formatDate (inputDate, format)  {
  if (!inputDate) return '';

  const padZero = (value) => (value.toString().padStart(2, '0'));
  const parts = {
      yyyy: inputDate.getFullYear(),
      MM: padZero(inputDate.getMonth() + 1),
      dd: padZero(inputDate.getDate()),
      HH: padZero(inputDate.getHours()),
      hh: padZero(inputDate.getHours()),
      mm: padZero(inputDate.getMinutes()),
      ss: padZero(inputDate.getSeconds()),
  };

  return format.replace(/yyyy|MM|dd|HH|hh|mm|ss/g, (match) => parts[match]);
}

export function shuffle(array) {
  // Create a copy of the array to avoid modifying the original array
  let shuffledArray = array.slice();

  for (let i = shuffledArray.length - 1; i > 0; i--) {
      // Generate a random index between 0 and i (inclusive)
      const j = Math.floor(Math.random() * (i + 1));

      // Swap elements at indices i and j
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }

  return shuffledArray;
}

export function wait(milliseconds){
  return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
  });
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateFormatText(text, maxLength) {
  if (maxLength <= 0) throw new Error("maxLength must be more than 0")

  if (text?.length <= maxLength) return text;

  return text?.substring(0, maxLength) + "...";
}

export function getCorrectedCountryCode(localeKey) {
  const map = {
    en: "gb",
    kk: "kz"
  };

  return map[localeKey] ?? localeKey;
}